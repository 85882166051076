import { Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';

export const MainDiv = styled.div`
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 1;
  color: #222;
  position: relative;
  -webkit-font-smoothing: antialiased;
  position: relative;
`;

export const InnerDiv = styled.div`
  border-radius: 3px;
  margin: 20px 0;
  background: none !important;
  border: 0 !important;
  .tab-content {
    border-radius: 0 0 3px 3px;
    display: block;
    background: whiteSmoke;
    padding: 10px;
    overflow: hidden;
  }
`;
export const TabsStyle = styled(Tabs)`
  border-bottom: 1px solid #d1d1d1;
  padding-left: 0px;
  margin: 0em;
  list-style: none;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  background: none !important;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1.1em;
  line-height: 1.3em;
  font-style: normal;
  li {
    background: rgba(255, 255, 255, 0.4);
    position: relative;
    bottom: -1px;
    margin-right: 2px;
    outline: 0;
    box-shadow: 0 1px 0 rgb(255 255 255 / 40%) inset;
    display: inline-block;
    vertical-align: bottom;
    margin-left: 0px;
    zoom: 1;
    font-size: 12px;
    font-weight: bold;
    top: auto;
    border-radius: 3px 3px 0 0;
    button {
      &.nav-link {
        outline: none;
        color: #777;
        text-shadow: 0 -1px 0 #ededed;
        text-decoration: none;
        border-bottom: none;
        padding: 0.5em 1em;
        float: left;
        border-radius: 3px 3px 0 0;
        &.active {
          background: whiteSmoke;
          border: 1px solid #d5d5d5;
          border-bottom: 0px;
          color: #333;
          text-shadow: 0 1px 0 #eee;
          border-color: transparent;
        }
      }
    }
  }
`;

export const ContentDiv = styled.div`
  background: whiteSmoke;
  padding: 10px;
  overflow: hidden;
  border-radius: 0 0 3px 3px;
`;

export const InnerContentDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
`;

export const ParaText = styled.p`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1.1em;
  line-height: 1.3em;
  font-style: normal;
  b {
    font-weight: bold;
    line-height: inherit;
    i {
      font-style: italic;
      line-height: inherit;
    }
  }
  i {
    font-style: italic;
    line-height: inherit;
  }
`;

export const OrderedList = styled.ol`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1.1em;
  line-height: 1.3em;
  font-style: normal;
  padding: 0 !important;
  margin-left: 20px;
`;

export const LinkStyle = styled.a`
  color: #263b91;
  text-decoration: none;
  line-height: inherit;
  :hover {
    color: #52b0e3;
  }
`;

import styled from "styled-components";

export const BackLink = styled.a`
    color: #263b91;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Titillium Web', sans-serif;
    :hover {
        color: #52b0e3;
    }
`;

export const BackButtonContainer = styled.div`
    margin: 20px 20px 20px 0;
`;

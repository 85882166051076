import React from 'react';
import Tab from 'react-bootstrap/Tab';
import * as Styled from './leftTabStyle';

const LeftTab = () => {
  return (
    <Styled.MainDiv>
      <Styled.InnerDiv>
        <Styled.TabsStyle
          defaultActiveKey="Exchange"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Exchange" title="Exchange">
            <Styled.ContentDiv>
              <Styled.InnerContentDiv>
                <Styled.ParaText>
                  <b>
                    <i>
                      If you have an empty cylinder that you wish to exchange
                      for a full one please follow these instructions:
                    </i>
                  </b>
                </Styled.ParaText>
                <br />
                <Styled.OrderedList>
                  <li>
                    {' '}
                    Select French language if needed. (Default - English)
                  </li>
                  <li>Swipe your VISA or MASTERCARD.</li>
                  <li>
                    Select{' '}
                    <b style={{ fontWeight: 'bold' }}>
                      <i>exchange.</i>
                    </b>
                  </li>
                  <li>
                    A verification screen will appear, to confirm your choice.
                  </li>
                  <li>
                    After verifying the exchange, an establishing connection
                    screen will appear.
                  </li>
                  <li>
                    Once the payment has been authorized, a screen will appear
                    showing the confirmation.
                  </li>
                  <li>
                    {' '}
                    Make sure the valve is closed securely on your cylinder.
                  </li>
                  <li>
                    Ensure that there is nothing connected to your cylinder such
                    as a supplemental valve, regulator or gauge
                  </li>
                  <li>You will hear an audio message.</li>
                  <li>
                    A door with an empty bay will open. Place your empty
                    cylinder inside
                  </li>
                  <li>
                    Once you close the door, verification will occur and the
                    next door in sequence from top to bottom will open with a
                    full refurbished cylinder inside.
                  </li>
                  <li>
                    Please take the cylinder out and close the door tightly.
                  </li>
                  <li>You have completed the cylinder exchange transaction.</li>
                  <li>
                    If you require a copy of your receipt, you can reach us by
                    phone{' '}
                    <Styled.LinkStyle href="tel:1-833-820-6080">
                      1-833-820-6080
                    </Styled.LinkStyle>{' '}
                    or by filling out the Contact Form found under the Contact
                    section.
                  </li>
                </Styled.OrderedList>
              </Styled.InnerContentDiv>
            </Styled.ContentDiv>
          </Tab>
          <Tab eventKey="Purchase" title="Purchase">
            <Styled.ContentDiv>
              <Styled.InnerContentDiv>
                <Styled.ParaText>
                  <b>
                    <i>
                      Please be advised that cylinders are refurbished and not
                      new.
                    </i>
                  </b>
                </Styled.ParaText>
                <br />
                <Styled.OrderedList>
                  <li>
                    {' '}
                    Select French language if needed. (Default - English)
                  </li>
                  <li>Swipe your VISA or MASTERCARD.</li>
                  <li>
                    Select{' '}
                    <b style={{ fontWeight: 'bold' }}>
                      <i>purchase.</i>
                    </b>
                  </li>
                  <li>
                    A verification screen will appear, to confirm your choice.
                  </li>
                  <li>
                    After verifying the exchange, an establishing connection
                    screen will appear.
                  </li>
                  <li>
                    Once the payment has been authorized, a screen will appear
                    showing the confirmation.
                  </li>
                  <li>
                    One of the three doors will open to your full refurbished
                    cylinder.
                  </li>
                  <li>
                    Please take the cylinder out and close the door tightly.
                  </li>
                  <li>You have completed the cylinder purchase transaction.</li>
                  <li>
                    If you require a copy of your receipt, you can reach us by
                    phone{' '}
                    <Styled.LinkStyle href="tel:1-833-820-6080">
                      1-833-820-6080
                    </Styled.LinkStyle>{' '}
                    or by filling out the Contact Form found under the Contact
                    section.
                  </li>
                </Styled.OrderedList>
              </Styled.InnerContentDiv>
            </Styled.ContentDiv>
          </Tab>
        </Styled.TabsStyle>
      </Styled.InnerDiv>
    </Styled.MainDiv>
  );
};

export default LeftTab;

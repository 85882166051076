import React from 'react';
import * as Styled from './helpBackButtonStyle';

const BackButton = () => {
  return (
    <Styled.BackButtonContainer>
      <Styled.BackLink href={`/help/`}>Back to Help</Styled.BackLink>
    </Styled.BackButtonContainer>
  );
};

export default BackButton;

import React from 'react';
import ColumnList from '../components/columnList/columnList';
import BootstrapNavbar from '../components/navgationBar/navigationBar';
import GlobalStyles from '../styles/globalStyles';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer/footer';
export default function Pro2GoKiosk() {
  return (
    <>
      <Helmet>
        <title>How to Use Pro2Go Kiosks | Pro2Go</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta name="format-detection" content="telephone=no"></meta>
        <meta
          name="keywords"
          content="bbq cylinders,propane cylinders,cylinder exchange service,london ontario,canad."
        ></meta>
        <meta
          name="description"
          content="Instructions if you have an empty propane cylinder that you wish to exchange for a full bbq propane cylinder"
        ></meta>
      </Helmet>
      <GlobalStyles />
      <BootstrapNavbar />
      <ColumnList />
      <Footer />
    </>
  );
}

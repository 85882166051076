import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 920px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 0 15px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 1;
  max-width: 100%;
  @media (max-width: 991px) {
    margin-top: 20px;
    padding: 0 30px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const HeaderH1 = styled.h1`
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  color: #4e4e4e;
  font-size: 1.75em;
  line-height: 1em;
  text-transform: uppercase;
  text-align: left;
  margin: 20px 0;
`;

export const ParaText = styled.p`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1.1em;
  line-height: 1.3em;
  font-style: normal;
  b {
    font-weight: bold;
    line-height: inherit;
  }
`;

export const LeftCol = styled(Col)`
  min-height: 1px;
  padding: 0 !important;
  position: relative;
  padding-right: 15px !important;
  @media (max-width: 767px) {
    padding: 0 !important;
  }
`;

export const RightCol = styled(Col)`
  min-height: 1px;
  padding: 0 !important;
  position: relative;
  padding-left: 15px !important;
  @media (max-width: 767px) {
    padding: 0 !important;
  }
`;

import React from 'react';
import { Row } from 'react-bootstrap';
import BackButton from '../helpBackButton/helpBackButton';
import LeftTab from '../leftTab/leftTab';
import RightTab from '../rightTab/rightTab';
import * as Styled from './columnListStyle';

const ColumnList = () => {
  return (
    <Styled.MainContainer>
      <Styled.HeaderH1>How to use PRO2GO Kiosks</Styled.HeaderH1>
      <Styled.ParaText>
        For complete instructions, please click the <em>exchange</em> or{' '}
        <em>purchase</em> tabs below.
      </Styled.ParaText>
      <Row className="g-0">
        <Styled.LeftCol xs={12} md={6}>
          <Styled.ParaText>
            <b>CREDIT CARD Transactions Only</b>
          </Styled.ParaText>
          <Styled.ParaText>
            Please follow the instructions below:
          </Styled.ParaText>
          <LeftTab />
        </Styled.LeftCol>
        <Styled.RightCol xs={12} md={6}>
          <Styled.ParaText>
            <b>CREDIT or DEBIT CARD Transactions</b>
          </Styled.ParaText>
          <Styled.ParaText>
            Please follow the instructions below:
          </Styled.ParaText>
          <RightTab />
        </Styled.RightCol>
      </Row>
      <Styled.ParaText>
        For assistance you can call our help line anytime at 1 833-820-6080.
      </Styled.ParaText>
      <BackButton />
    </Styled.MainContainer>
  );
};

export default ColumnList;
